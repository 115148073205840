import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import "../../styles/main.scss"

const Airegio = () => {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 600)
  }, [])

  return loader ? (
    <div className="loader" />
  ) : (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <article>
        <div className="container">
          <h2>AIM4PS - AI Manufacturing for Public Sector</h2>
          <figure>
            <img
             src={"/ai_regio.png"}
            />
          </figure>
          <p>
          The <a className="a-blue" href="https://www.airegio-project.eu/" target="_blank">AI REGIO</a> “Regions and Digital Innovation Hubs alliance for AI-driven digital transformation of European Manufacturing SMEs" is an initiative aimed at promoting the use of Artificial Intelligence (AI) to support regional development across Europe. 
          </p>
          <p>
          The main objective of this project is to enable regions to become more competitive and resilient by leveraging the potential of AI to boost productivity, innovation, and growth. 
          </p>
          <p>
          The AI REGIO will build a one-stop-shop platform that enables access to resources for AI-based solutions in efficient and sustainable manufacturing, with particular emphasis on resources that can lower the AI adoption barriers for SMEs.
          </p>
          <hr />
          <h3>AI Manufacturing for Public Sector - AIM4PS</h3>
          <p className="mt-2">
          The AI Manufacturing for Public Sector (AIM4PS) project will enhance the digitalization of the end-to-end manufacturing process by bringing together the power of public sector open data with manufacturing production planning, inventory management, and logistic organization. 
          </p>
          <p>
          The AIM4PS project will complement the existing AI REGIO offering with an AI Asset for smart and dynamic market forecasting tailored to the specific needs of manufacturing companies through the development of AIM4PS AI Asset.
          </p>
          <h3>AIM4PS AI Asset</h3>
          <p>AIM4PS AI Asset will employ state-of-the-art AI methodologies for intaking and processing public procurement data, taking as a reference the specific production- and product-related information collected from manufacturing EISs. The AIM4PS AI Asset will combine this information and generate a dynamic and interactive forecasting solution, allowing SMEs to better understand the needs of public sector clients, better characterize their market space, and ultimately lead to automated transformations in their complete manufacturing value chain, from production to logistics.
          </p>
          <figure>
            <img
             src={"/airegio_arch.png"}
            />
            <figcaption>Draft Architecture for the AIM4PS AI Asset</figcaption>
          </figure>
          <p>
          The AI Asset will realize an innovative smart and dynamic forecasting solution to present prospective business in the public market for manufacturing companies, in an automated fashion. By considering these novel insights, presented via an open-source Business Intelligence (BI) visualization solution (Redash) and available through open-source API (gRPC), manufacturers will have the opportunity to adapt their complete manufacturing value chains by adjusting production plans, customizing products to effectively respond to market demands, and better coordinating logistic resources.
          </p>
          <h3>Expected Impact</h3>
          <p>Without the proposed dynamic and smart forecasting solution, manufacturers will only be able to do educated guesses on which type of public entities would be a good fit for their products, not knowing the segment value, how many competitors exist in the same space, or how to optimize their marketing efforts and investment on potential buyers. Manufacturers may contract a consulting partner to study this information and create analysis reports on these topics. However, this type of service is often expensive, manual, and static in time. 
          </p>
          <p>
          The proposed AIM4PS AI Asset is an advanced manufacturing solution that equips manufacturing companies with an effective tool that will continuously contribute to a better organization of the complete manufacturing value chain.</p>
          <figure className="ue-horizon-logo">
              <img
             src={"/horizon-2020.jpeg"}
              />
              <figcaption>This project has received cascading funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 952003.</figcaption>
            </figure>
        </div>
      </article>
    </Layout>
  )
}

export default Airegio;

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`   